@import '@styles/mixins';
@import '@styles/variables';

.root {
	color: white;
	background-color: #fafafa;

	&:not(.nested-navigation) {
		@include box-shadow(0px, 0px, 15px, rgba(0, 0, 0, 0.10));
	}
}

.hover-area {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.section-header-root {
	color: #3a434b;
}

.section-header-divider {
	border-top: 1px solid #ebebeb !important;
}

.navigation-link-root {
	color: #3a434b;

	&.navigation-link-expanded {
		color: #3a434b;
	}
	
	&.navigation-link-nested {
		color: #7f858a;
	}

	&:hover {
		color: #2c353d;
	}
}
