@import '@styles/mixins';
@import '@styles/variables';
@import '@styles/animations';

.root {
	position: relative;
	display: flex;
    align-items: center;
    justify-content: center;
	width: 100%;
	height: 100%;
	min-height: 240px;
	cursor: pointer;
	background-color: grey;
	@include round-corner(5px);

	&:hover {
		.gradient {
			@include animation('darkenGradient 0.5s 1');
			opacity: 1;
		}
	}

	.link-overlay {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.details {
		position: absolute;
		z-index: 2;
		left: 0;
		bottom: 0;
		width: 100%;
		font-size: 14px;
		text-align: left;

		.address {
			color: white;
		}

		.suburb {
			color: #cecece;
		}

		.angle-right {
			position: absolute;
			top: calc(50% - 12px);
			right: 10px;
			font-size: 24px;
			color: white;
		}
	}

	.icons {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		right: 0;
		top: 0;
		color: white;
	}

	.gradient {
		display: block;
		position: absolute;
		z-index: 1;
		bottom: 0;
		width: 100%;
		height: 40%;
		opacity: 0.7;
		pointer-events: none;
		@include gradient(bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
		@include round-bottom-corners(5px);
	}

	&.no-image {
		.gradient {
			@include gradient(bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		}

		.details {
			.address {
				color: #696969;
			}

			.suburb {
				color: #a9a9a9;
			}
		}
	}
}
